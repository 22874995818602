import { DesignModel, DesignObject, RunModel, AutomationParameterModel, SampleModel, ApplicationsResponse } from "../model/run-model"
import * as API from "../api"
import { v4 } from "uuid"
import { ApiResponse } from "apisauce"
import * as jobService from "./job-service"
import { ServerBarcode, UiRun, UiCollection, UiBiosample } from "../model/ui-run-model"
import { parseXML } from "../utils/shared/xml/parse-xml"
import { PipelineTemplate, PipelineTemplateMap } from "../model/pipeline-template-models"
import { apiCache } from "./api-cache"
import { reportError } from "../../core-components/shared/Error/ErrorBoundary"
import { getMultiJobApplicationName } from "../services/job-service"
import LOCAL_TEST_DEFAULTS from "../utils/run-design/LocalRunDesignDefaults.json"
// import { Job } from "../model/job-model"
import * as _ from "lodash"
import { RunDesign } from "../model/run-design-model"
import { getLabelNumber, getOldBarcode, getPlateBarcode, makeNewBarcode } from "../../silos/runs/utils/helpers"
import { Dataset } from "../model/dataset-model"
import { BARCODES_THAT_EXCLUDE_SAME_STRAND_PAIRS, BARCODES_WITH_FIXED_PAIRS } from "../../core-components/shared/BarcodeSampleOptions/barcode-constants"
import { RunQcObject } from "../model/run-qc-model"
import { isRevioOrVega } from "../model/instrument-type-model"
import { cleanupUsername } from "../model/user-model"

const DEBUG_USE_LOCAL_TEST_DEFAULTS = false;

export const PRE_ANALYSIS_IDS = [
    "cromwell.workflows.pb_demux_subreads",
    "cromwell.workflows.pb_ccs_demux",
    "cromwell.workflows.pb_ccs",
    "cromwell.workflows.pb_export_ccs",
    "cromwell.workflows.pb_demux_ccs"
]

export const getDesign = (id: string | number): Promise<DesignModel> => {

    return API.aRun(id).then(
        async response => {
            const run: RunQcObject = response.data
            let model = await responseToDesignModel(run)
            try {
                const runDesignReponse = await API.getRunDesign(id.toString())
                if (runDesignReponse) {
                    addRunDesignData(runDesignReponse.data, model)
                }
            } catch {} //okay for a run to not have a run-design found in SL.

            return model
        },
        error => {
            if( !(error instanceof API.ResourceNotFoundError) ) {
                reportError(error.originalError || "Unknown Run Design Error")
            }
            throw error
        }
    )
}

export const addRunDesignData = (runDesign: RunDesign, model: DesignModel) => {
    model.run.samples.forEach((sample, index) => {
        sample.barcodeCSVName = runDesign.samples[index].barcodeCsvFileName
        sample.application = runDesign.samples[index].application
        if (runDesign.samples[index].libraryType) {
            sample.libraryType = runDesign.samples[index].libraryType
        }
        sample.analysisName = runDesign.samples[index].analysisName  // TODO: "AutoAnalysisName" or "analysisName" ???
                                                                    // inside autoAnalysisEnabled or not ??
        sample.pipelineId = runDesign.samples[index].pipelineId
        sample.entryPoints = runDesign.samples[index].entryPoints
        sample.taskOptions = runDesign.samples[index].taskOptions
        sample.presetId = runDesign.samples[index].presetId
        sample.consensusMode = runDesign.samples[index].consensusMode

        if (runDesign.samples[index].autoAnalysisEnabled) {
            sample.AutoAnalysisEnabled = runDesign.samples[index].autoAnalysisEnabled
            sample.AutoAnalysisName = runDesign.samples[index].AutoAnalysisName
            if (sample.application === "masSeq") {
                sample.adapter = runDesign.samples[index].adapter
                sample.cellType = runDesign.samples[index].cellType
                sample.scIsoSeq = runDesign.samples[index].scIsoSeq
                sample.readSegmentation = runDesign.samples[index].readSegmentation
            }
        }
        if (runDesign.samples[index].isBarcoded) {
            sample.isBarcoded = true
            sample.barcodeUuid = runDesign.samples[index].barcodeUuid
            sample.sameBarcodesBothEnds = runDesign.samples[index].symmetricBarcodes
            sample.minBarcodeScore = 0
            sample.demuxBarcodes = runDesign.samples[index].demuxMode
            sample.barcodeCSVName = runDesign.samples[index].barcodeCsvFileName
            runDesign.samples[index].barcodedSamples.forEach(barcodedSample => {
                sample.barcodeUuidMap[barcodedSample.dnaBarcodeName] = barcodedSample.uuid
                sample.barcodeSampleMap[barcodedSample.dnaBarcodeName] = barcodedSample.bioSampleName
                if (barcodedSample.sampleData) {
                    sample.assayPlateIdMap[barcodedSample.dnaBarcodeName] = barcodedSample.sampleData.assayPlateId
                    sample.assayPlateWellMap[barcodedSample.dnaBarcodeName] = barcodedSample.sampleData.assayPlateWell
                }
            })
        }
    })
}

export const saveRunDesign = (runDesign: RunDesign, update: Boolean): Promise<RunDesign> => {
    if (update) {
        return API.updateRunDesign(runDesign).then(response => {
            return API.getRunDesign(response.data.uniqueId).then(response => {
                return response.data
            })
        },
        error => {
            if(error.data && error.data.message) {
                reportError(error.data.message)
            }
            throw error
        })
    } else {
        return API.addRunDesign(runDesign).then(response => {
            return API.getRunDesign(response.data.uniqueId).then(response => {
                return response.data
            })
        },
        error => {
            if(error.data && error.data.message) {
                reportError(error.data.message)
            }
            throw error
        })
    }
}

export const getUiRun = (uuid: string): Promise<UiRun> => {
    return getDesign(uuid).then( design => designModelToUiRun(design))
}

export const deleteDesign = (runUuid: string): Promise<ApiResponse<void>> => {
    return API.deleteRunDesign(runUuid)
}


export const getDesignFromMultiJobId = (id: number): Promise<DesignModel> => {
    return API.nRunsMultiJobId(id).then (response => getDesign(response.data[0].uniqueId))
}

export const getRuns = (projectId: number): Promise<UiRun[]> => {
    return getDesigns(projectId).then(designs => designs.map(designModelToUiRun))
}

const designModelToUiRun = (design: DesignModel): UiRun => {
    return {
        name: design.name,
        totalCells: design.totalCells,
        multiJobId: design.multiJobId || null,
        uniqueId: design.uniqueId,
        runSummary: design.runSummary,
        createdAt: design.createdAt,
        createdBy: cleanupUsername(design.run.createdBy),
        reserved: design.reserved
    }
}

export const getDesigns = (projectId: number): Promise<DesignModel[]> => {
    return API.nRunsAsDesigns(projectId).then( (runs) => {
        let ps: Promise<DesignModel>[] = []
        runs.data.forEach(
            run => {
                ps.push(responseToDesignModel(run))
            }
        )
        return Promise.all(ps)
    })
}

export const getCollections = (runId, projectId): Promise<UiCollection[]> => {
    return getDesign(runId).then(design => {
        return jobService.getCollectionJobIds(design, projectId).then(jobIdDict => {
            let collections: UiCollection[] = []
            design.run.samples.forEach(
                sample => {
                    const matchesProject = (projectId && projectId > 0) ? projectId === sample.projectId : true
                    if (matchesProject) {
                        let collection:UiCollection  = {
                            collectionName: sample.sampleName,
                            wellName: sample.wellName,
                            collectionUniqueId: sample.subreadSetUuid,
                            ccsUniqueId: sample.ccsUuid,
                            runName: design.run.runName,
                            runUniqueId: design.run.uuid,
                            jobId: jobIdDict[sample.subreadSetUuid] || null,
                            multiJobId: sample.multiJobId || null,
                            createdAt: design.createdAt,
                            numChildren:
                                Object.keys(sample.barcodeSampleMap).length > 0 ? Object.keys(sample.barcodeSampleMap).length : null
                        }
                        collections.push(collection)
                    }
                }
            )
            return collections
        })
    })
}

export const getBioSamples = (runId, collectionId): Promise<UiBiosample[]> => {
    return getDesign(runId).then(design => {
        let collection: SampleModel
        design.run.samples.forEach(sample => {
            if (sample.subreadSetUuid === collectionId) {
                collection = sample
            }
        })
        return jobService.getMultiJobJobIds(collection.multiJobId).then(jobIdDict => {
            let ps: UiBiosample[] = []
            Object.keys(collection.barcodeUuidMap).forEach(b => {
                let bioSample: UiBiosample = {
                    barcodeName: b,
                    barcodeUniqueId: collection.barcodeUuidMap[b],
                    bioSampleName: collection.barcodeSampleMap[b],
                    collectionName: collection.sampleName,
                    collectionUniqueId: collection.subreadSetUuid,
                    collectionCcsUniqueId: collection.ccsUuid,
                    runName: design.run.runName,
                    runUniqueId: design.run.uuid,
                    jobId: jobIdDict[collection.barcodeUuidMap[b]] || null,
                    multiJobId: collection.multiJobId || null
                }
                ps.push(bioSample)
            })
            return ps
        })
    })
}

export const getBarcodeSets = async () => {
    return API.nDatasets({ setType: "barcodes"}).then(({ data: barcodeSets }) => {
        return barcodeSets
    })
}

export const getBarcodePairs = async (
    barcodes: Dataset,
    isSymmetric: boolean
): Promise<string[]> => {

    if (!barcodes) {
        return null
    }

    const excludeSameStrandPairs = BARCODES_THAT_EXCLUDE_SAME_STRAND_PAIRS.includes(barcodes.name)
    const isFixedPairs = BARCODES_WITH_FIXED_PAIRS.includes(barcodes.name)

    if (isSymmetric) {
        return API.nRecordNamesByUuid("barcodes", barcodes.uuid).then(response => {
            return response.data.map(barcode => barcode + "--" + barcode)
        }, () => {
            return []
        })
    } else {
        return API.nRecordNamesByUuid("barcodes", barcodes.uuid).then(response => {
            let recordNames: string[] = response.data
            let pairs: string[] = []
            for (let a = 0; a < recordNames.length - 1; ++a) {
                for (let b = a + 1; b < recordNames.length; ++b) {
                    const bc1 = recordNames[a]
                    const bc2 = recordNames[b]
                    if (excludeSameStrandPairs) {
                        // Don't allow FF or RR pairs (marked by suffices, e.g. "_F" or "_R")
                        if ( bc1[bc1.length - 1] === bc2[bc2.length - 1]) {
                            continue
                        }
                    }
                    if (isFixedPairs) {
                        // Only allow pairs of the form "foo_F" / "foo_R", i.e. with same name before strand letter.
                        if (bc1.length !== bc2.length) { continue }
                        if (bc1 === bc2) { continue}
                        const n =  bc1.length
                        if (bc1.slice(0,n-1) !== bc2.slice(0,n-1)) { continue }
                    }
                    pairs.push(bc1 + "--" + bc2)
                }
            }
            return pairs
        }, () => {
            return []
        })
    }
}

export const addDesignBarcodes = async (design): Promise < DesignModel > => {
    const runId = design.run.uuid
    for (let i = 0; i < design.run.samples.length; i++) {
        let sample = design.run.samples[i]
        if (sample.isBarcoded) {
            let collectionId = sample.subreadSetUuid
            let barcodes = makeBarcodeData(sample)
            await API.saveCollectionBarcodes( {runId, collectionId, barcodes} )

        }
    }
    return Promise.resolve(design)
}

const makeBarcodeData = (sample): ServerBarcode[] => {
    let barcodeData: ServerBarcode[] = []
    Object.keys(sample.barcodeSampleMap).forEach(bc => {
        let barcode: ServerBarcode = {
            dnaBarcodeName: bc,
            bioSampleName: sample.barcodeSampleMap[bc],
            uuid: sample.barcodeUuidMap[bc],
            sampleData: {
                assayPlateId: sample.assayPlateIdMap[bc],
                assayPlateWell: sample.assayPlateWellMap[bc]
              }
        }
        barcodeData.push(barcode)
    })
    return barcodeData
}

export const responseToDesignModel = async (response): Promise<DesignModel> => {
    if (response.dataModel) {
        response.run = parseXML(response.dataModel)
        let { data: barcodeSets } = await API.nDatasets({ setType: "barcodes" })
        let jobSettings = []
        if (response.hasOwnProperty("multiJobId")) {
            await processMultiJobsOld(response, jobSettings)
        } else {
            await processMultiJobsNew(response, jobSettings)
        }
        await addCollectionInfo(response)
        const barcodingData = await addBarcodingData(response, jobSettings, barcodeSets)
        return new DesignModel(barcodingData)
    }
    return Promise.resolve(new DesignModel(response))
}

export const getRunsSequencingKit = (plateBarcode: string) => {
    return API.nRunsSequencingKit(getOldBarcode(plateBarcode), getLabelNumber(plateBarcode))
}

export const getUsedWells = (response: DesignModel): Promise<any[]> => {
    let assignedWells = []
    if (isRevioOrVega(response.run.instrumentType)) {
        let sequencingKits =
        _.uniq(response.run.samples.map(sample => {return  getPlateBarcode(response.run, sample.plateNumber)}))
        let promises = sequencingKits.map( sequencingKit => {
            return getRunsSequencingKit(sequencingKit).then(assignedWellRuns => {
                let assignedWellRunUUIDs = assignedWellRuns.data.map(run => {return run.uniqueId})
                assignedWellRunUUIDs = assignedWellRunUUIDs.filter(id => id !== response.uniqueId)
                let assignedWellRunDetailsPromises = assignedWellRunUUIDs.length > 0 ?
                assignedWellRunUUIDs.map(uuid => API.getRunDesign(uuid)) : []
                return Promise.all(assignedWellRunDetailsPromises).then(assignedWellRunDetails => {
                    if (assignedWellRunDetails) {
                        assignedWellRunDetails.forEach(details => {
                            details.data.samples.forEach(assignedSample => {
                                if ( makeNewBarcode(assignedSample.sequencingKit, assignedSample.labelNumber) === sequencingKit) {
                                    if (assignedSample.plateNumber === 1) {
                                        assignedWells.push("1_" + assignedSample.wellName)
                                    }
                                    if (assignedSample.plateNumber === 2) {
                                        assignedWells.push("2_" +assignedSample.wellName)
                                    }
                                }
                            })
                        })
                    }
                    return assignedWells
                })
            })
        })
        return Promise.all(promises).then(wellArrays => {
            let allAssignedWells = []
            wellArrays.forEach(assignedWells => {
                allAssignedWells = allAssignedWells.concat(assignedWells)
            })
            return allAssignedWells
        })
    }
    return Promise.all([])
}

export const addCollectionInfo = async (response) => {
    response.run.samples.forEach(async sample => {
        let collectionInfo = await API.getCollectionInfo(response.run.uuid, sample.subreadSetUuid)
        if (collectionInfo.data["compatibilityTags"]) {
            let compatibilityTags = JSON.parse(collectionInfo.data["compatibilityTags"])
            sample.AutoAnalysisEnabled = compatibilityTags["AutoAnalysisEnabled"]
            sample.AutoAnalysisName = compatibilityTags["AutoAnalysisName"]
        }
    })
}

export const processMultiJobsNew = async (response: DesignObject, jobSettings) => {
    let getMultiJobJobsList = []
    response.run.samples.forEach(sample => {
        let getMultiJobJobs = sample.multiJobId ?
            API.nMultiJobJobs("multi-analysis", sample.multiJobId).then(response => {
                return response.data
            }) : Promise.resolve([])
        getMultiJobJobsList.push(getMultiJobJobs)
    })
    let multiJobJobsList = await Promise.all(getMultiJobJobsList)
    response.run.samples.forEach(
        (sample, index) => {
            multiJobJobsList[index]?.forEach(job => {
                let settings = JSON.parse(job.jsonSettings || "")
                if (PRE_ANALYSIS_IDS.includes(settings.pipelineId)) {
                    settings.entryPoints.forEach(eP => {
                        if (((eP.entryId === "eid_subread" && eP.datasetId === sample.subreadSetUuid) ||
                            (eP.entryId === "eid_ccs" && eP.datasetId === sample.ccsUuid))
                            ) {
                            sample["preAnalysisType"] = job.name
                            jobSettings.push(settings)
                        }
                    })
                } else {
                    sample.hasAutoAnalyses = true
                }
            })
        })
}

export const processMultiJobsOld = async (response: DesignObject, jobSettings) => {
    const { data: multiJobJobs } = await API.nMultiJobJobs("multi-analysis", response.multiJobId)
    response.run.samples.forEach(
        sample => {
            multiJobJobs.forEach(job => {
                let settings = JSON.parse(job.jsonSettings || "")
                settings.entryPoints.forEach(eP => {
                    if ((eP.entryId === "eid_subread" && eP.datasetId === sample.subreadSetUuid) ||
                        (eP.entryId === "eid_ccs" && eP.datasetId === sample.ccsUuid)) {
                        sample["preAnalysisType"] = job.name
                    }
                })
            })
        })
    multiJobJobs.forEach(job => {
        jobSettings.push(JSON.parse(job.jsonSettings))
    })
}

const addBarcodingData = async (response, jobSettings?, barcodesets?): Promise<DesignModel> => {
    const uuidMultiJobMap = {}
    let promises: Promise<void>[] = []
    if (jobSettings) {
        jobSettings.forEach(job => {
            if (
                job.pipelineId === "cromwell.workflows.pb_demux_subreads" ||
                job.pipelineId === "cromwell.workflows.pb_ccs_demux" ||
                job.pipelineId === "cromwell.workflows.pb_demux_ccs"
            ) {
                const uuidDict = {}
                job.entryPoints.forEach(ep => {
                    uuidDict[ep.entryId] = ep.datasetId
                })
                const optionsMap = {}
                job.taskOptions.forEach(o => {
                    optionsMap[o.id] = o.value
                })
                const primaryDatasetId = job.pipelineId === "cromwell.workflows.pb_demux_ccs" ?
                    "eid_ccs" : "eid_subread"
                uuidMultiJobMap[uuidDict[primaryDatasetId]] = {
                    barcodeUuid: uuidDict["eid_barcode"],
                    sameBarcodesBothEnds: optionsMap["lima_symmetric_barcodes"]
                }
            }
        })
    }

    if (response.run && response.run.samples) {
        response.run.samples.forEach(sample => {
            const sampleId = sample.enableCCS === "None" ? sample.subreadSetUuid : sample.ccsUuid
            const multiJob = uuidMultiJobMap[sampleId]

            if (multiJob) {
                let barcodesPromise = API.nRunsBarcodes(response.run.uuid, sample.subreadSetUuid).then(
                    ({ ok: barcodesOk, data: barcodes }) => {
                        if (!barcodesOk) {
                            return
                        }
                        if (barcodes!.length > 0) {
                            sample.barcodeSampleMap = {}
                            sample.barcodeUuidMap = {}
                            sample.assayPlateIdMap = {}
                            sample.assayPlateWellMap = {}
                            barcodes!.forEach(barcode => {
                                sample.barcodeSampleMap[barcode.dnaBarcodeName] = barcode.bioSampleName
                                sample.barcodeUuidMap[barcode.dnaBarcodeName] = barcode.uuid
                                if (barcode.sampleData) {
                                    sample.assayPlateIdMap[barcode.dnaBarcodeName] = barcode.sampleData.assayPlateId
                                    sample.assayPlateWellMap[barcode.dnaBarcodeName] = barcode.sampleData.assayPlateWell
                                }
                            })
                        }
                    })
                promises.push(barcodesPromise)
                sample.isBarcoded = true
                sample.barcodeUuid = multiJob.barcodeUuid
                sample.sameBarcodesBothEnds = multiJob.sameBarcodesBothEnds
                sample.minBarcodeScore = 0
            }
            if (barcodesets) {
                for (let barcodeset of barcodesets) {
                    if (barcodeset.uuid === sample.barcodeUuid) {
                        sample.barcodeName = barcodeset.name
                    }
                }
            }
        })
    }

    await Promise.all(promises)

    return Promise.resolve(response)
}

const getPipelineTemplatesMap: () => Promise <PipelineTemplateMap> = () => {

    return apiCache.getPipelineTemplates().then(templates => {
        if (!templates) { return {} }
        return templates.reduce(
            (templatesObject: any, template: PipelineTemplate) => {
                let property = {}
                property[template.id] = template
                return Object.assign(templatesObject, property)
            },
            {}
        )
    })
}


export const getDefaults: () => Promise<ApplicationsResponse> = () => {
    if (DEBUG_USE_LOCAL_TEST_DEFAULTS) {
        return Promise.resolve(LOCAL_TEST_DEFAULTS)
    }
    try {
        return apiCache.getRunDesignDefaults()
    } catch {
        return Promise.resolve({
            applicationCategories: [],
            applications: {}
        })
    }
}

export const getSampleTable: (design: DesignModel) => Promise<any> = (design) => {
    return getPipelineTemplatesMap().then(templateMap => {
        let ps: Promise<any>[] = []
        design.run.samples.forEach(
            sample => {
                if (sample.multiJobId) {
                    let promise = API.getMultiJob(sample.multiJobId).then(response => {
                        sample["autoAnalysisType"] = getMultiJobApplicationName(response.data.subJobTypeId, templateMap)
                    })
                    ps.push(promise)
                }
            }
        )
        return Promise.all(ps).then(() => {return design})
    })
}
export function populateRunValues(run: any) {
    if (run.uuid === "") {
        run.uuid = v4()
    }
    run.samples.forEach(s => {
        if (s.subreadSetUuid === "") {
            s.subreadSetUuid = v4()
        }
        if (s.collectionMetadataUuid === "") {
            s.collectionMetadataUuid = v4()
        }
        if (s.enableCCS !== "None" && s.ccsUuid === "") {
            s.ccsUuid = v4()
        }
        if (s.isBarcoded) {
            Object.keys(s.barcodeSampleMap).forEach((barcode) => {
                s.barcodeUuidMap[barcode] = s.barcodeUuidMap[barcode] || v4()
            })
        }
    })
}

export function removeDuplicateAutomationParameters(run: RunModel) {
    for (let sample of run.samples) {
        if (sample.automationParameters && sample.automationParameters.length > 0) {
            let apMap = new Map()
            for (let ap of sample.automationParameters) {
                apMap.set(ap.name, ap)
            }
            let uniqueAPs: AutomationParameterModel[] = []
            // eslint-disable-next-line
            for (let [name, ap] of apMap.entries()) {
                uniqueAPs.push(ap)
            }
            sample.automationParameters = uniqueAPs
        }
    }
}
